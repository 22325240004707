import WelcomeModalController from '@/components/dashboard/WelcomeModel'
import Error from '@/components/Error'
import { DashboardHeader } from '@/components/Headers'
import Navbar from '@/components/Navbar'
import WhatsNewModal from '@/components/WhatsNewModal'
import AgencyProvider from '@/providers/Agency.provider'
import AgencySelectModalProvider from '@/providers/AgencySelectModal.provider'
import ClientsProvider from '@/providers/Clients.provider'
import CouponProvider from '@/providers/Coupon.provider'
import ManualGrantsProvider from '@/providers/ManualGrants.provider'
import PlatformsProvider from '@/providers/Platforms.provider'
import SubscriptionProvider from '@/providers/Subscription.provider'
import UserProvider from '@/providers/User.provider'
import api from '@/utils/api'
import { AgencyContext } from '@/utils/context/Agency.context'
import { SubscriptionContext } from '@/utils/context/Subscription.context'
import { auth } from '@/utils/firebase'
import { Box, Button, Image, Modal, Paper, PinInput, Text } from '@mantine/core'
import { notifications } from '@mantine/notifications'
// import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { AlertCircle, ArrowRight, InfoCircle } from 'tabler-icons-react'
import '@mantine/dates/styles.css';

const VerifyEmailAddressModal = ({ close, hideBanner }: { close: VoidFunction, hideBanner: VoidFunction }) => {
    const [resendableIn, setResendableIn] = useState(30)
    const [codeInput, setCodeInput] = useState("")
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState("")

    const sendCode = async () => {
        setResendableIn(30)
        await api.post("/account/email/verification-code")
        notifications.show({
            title: "Code sent",
            message: `We sent a code to ${auth.currentUser?.email}`,
            color: "green"
        })
    }

    const submitCode = () => {
        setLoading(true)
        api.post("/account/email/verify", {
            code: parseInt(codeInput)
        }).then((res) => {
            setLoading(false)
            if (res.error) {
                return setError(res.msg)
            }
            notifications.show({
                title: "Email verified",
                message: "Your email has been verified successfully",
                color: "blue"
            })
            auth.currentUser?.reload()
            hideBanner()
            close()
        })
    }

    useEffect(() => {
        if (codeInput.length === 6) {
            submitCode()
        }
    }, [codeInput])

    useEffect(() => {
        sendCode()
    }, [])

    useEffect(() => {
        if (resendableIn <= 0) return;
        setTimeout(() => {
            setResendableIn((ri) => ri - 1)
        }, 1000)
    }, [resendableIn])

    return (
        <Box>
            <Box w="100%">
                <Text fz="md" fw={600}>Verify your email</Text>
                <Text fz="sm" lh={1} c="dimmed" >We sent a code to <span style={{ color: "#000" }}>{auth.currentUser?.email}</span></Text>
            </Box>
            <Box pt={15}>
                <PinInput length={6} type={"number"} disabled={loading} value={codeInput} onChange={setCodeInput} size="sm" radius={10} />
                <Text onClick={() => resendableIn <= 0 ? sendCode() : void 0} className={resendableIn <= 0 ? "cp" : ""} c={resendableIn <= 0 ? "blue" : "dimmed"} mt={5} fz="sm">Resend code {resendableIn > 0 ? `in ${resendableIn}s` : ""}</Text>
            </Box>
            <Button loading={loading} onClick={() => codeInput.length === 6 ? submitCode() : void 0} fullWidth mt={12}>Submit</Button>
            <Error mt={10}>{error}</Error>
        </Box >
    )
}

const VerifyEmailAddress = () => {
    const [open, setOpen] = useState(false)
    const [bannerForceHidden, setBannerForceHidden] = useState(false)
    return (
        <>
            <Modal
                opened={open}
                onClose={() => setOpen(false)}
                withCloseButton={false}
            >
                <VerifyEmailAddressModal hideBanner={() => setBannerForceHidden(true)} close={() => setOpen(false)} />
            </Modal>
            {!bannerForceHidden
                ? <Paper shadow='xs' p="sm" radius={10} className='flex aic jcsb' mb={10}>
                    <Box className='flex' style={{ gap: 12 }}>
                        <Text fz={20} className='wave'>📧</Text>
                        <Box>
                            <Text fw={600} fz="md" className='flex aic' style={{ gap: 7 }}>Verify your email</Text>
                            <Text c="dimmed" fz="sm">You haven't verified your email address yet. Verify your email address to secure your account.</Text>
                        </Box>
                    </Box>
                    <Button onClick={() => setOpen(true)} size='xs' variant="default">Verify Email</Button>
                </Paper>
                : null
            }

        </>
    )
}

const ReLinkMeta = () => {
    return (
        <Paper bg={"linear-gradient(-45deg, rgba(34,138,229,1) 0%, rgba(0,0,0,1) 100%)"} shadow='xs' p="sm" radius={10} className='flex aic jcsb' mb={10}>
            <Box className='flex' style={{ gap: 12 }}>
                {/* <Box bg="#fff" w={45} h={45} style={{borderRadius: 100}} className='flex aic jcc'> */}
                <Image src={"/images/logos/meta.png"} style={{ width: 30, objectFit: "contain" }} alt='Meta Logo' />

                {/* </Box> */}
                {/* <Text fz={20} className='wave'>📧</Text> */}
                <Box>
                    <Text c={"#fff"} fw={600} fz="md" className='flex aic' style={{ gap: 7 }}>Relink your Meta account</Text>
                    <Text c="dimmed" fz="sm">We recently released Meta Ads and Product Catalog for Meta. To request access to these new services, please re-link your Meta account.</Text>
                </Box>
            </Box>
            <Button component={Link} href={"/dashboard/accounts"} miw={"fit-content"} c="#fff" rightSection={<ArrowRight size={18} />} variant="light" >Re-link Account</Button>
        </Paper>
    )
}

const MainContentController = ({ children }: PropsWithChildren) => {
    const router = useRouter()
    const agency = useContext(AgencyContext)
    const subscriptionActive = useContext(SubscriptionContext)
    return (
        <div style={{ flex: 1, height: "calc(100dvh - 80px)", overflow: "hidden", position: "relative" }} className='flex fdc' id='main-contnet-container'>
            <main style={{ padding: 25, flex: 1, background: "#f9f9f9", maxHeight: "100%", overflowY: "auto", position: "relative", minWidth: 850 }} className="flex jcc" id='main-padded-contnet-container'>
                <div style={{ maxWidth: 1500, width: "100%", height: "fit-content" }} className='flex fdc'>
                    {!subscriptionActive && agency.subscriptionExpires as number < Date.now()
                        ? <Paper withBorder radius={"lg"} p="md" mb={15} color={"red"}>
                            <div className='flex aic jcsb' style={{ gap: 15 }}>
                                <div className='flex' style={{ gap: 10 }}>
                                    <AlertCircle color='#fa5252' />
                                    <div>
                                        <Text fw={700} fz={16} c={"red"}>Subscription Expired</Text>
                                        <Text fz={"sm"}>
                                            Your subscription has expired. Please renew your subscription to regain access to AgencyAccess.
                                        </Text>
                                    </div>

                                </div>
                                <Link href={"/dashboard/settings/billing"}>
                                    <Button variant={"default"}>Manage Subscription</Button>
                                </Link>
                            </div>
                        </Paper>
                        : <>
                            {!subscriptionActive
                                ? <Paper mb={15} bg="red.1" p={15} color={"red"} title="Your subscription has been canceled">
                                    <div className='flex' style={{ gap: 10 }}>
                                        <InfoCircle color='var(--mantine-color-red-6)' />
                                        <div>
                                            <Text c="red" fw={600}>Your subscription has been canceled</Text>
                                            <Text c="black" fz={"sm"}>You'll loose access to your account on <strong>{new Date(agency.subscriptionExpires as number).toDateString()}</strong>. Renew your subscription to maintain access.</Text>
                                            <Link href={"/dashboard/settings/billing"}>
                                                <Button variant={"default"} mt={10}>Manage Subscription</Button>
                                            </Link>
                                        </div>
                                    </div>

                                </Paper>
                                : null
                            }
                        </>
                    }
                    {
                        !auth.currentUser?.emailVerified
                            ? <VerifyEmailAddress />
                            : null
                    }
                    {agency.linkedAccounts.Meta?.find((a) => !a.hasNewPerms)
                        ? <ReLinkMeta />
                        : null
                    }

                    <div style={{ position: "relative", flex: 1, minHeight: "100%" }}>
                        {!subscriptionActive && agency.subscriptionExpires as number < Date.now() && !window.location.pathname.startsWith("/dashboard/settings/billing")
                            ? <Box style={{ position: "absolute", zIndex: 10000, width: "100%", height: "100%", left: 0, right: 0, background: 'rgba(255,255,255, .5)' }}></Box>
                            : null
                        }
                        {/* {router.pathname.startsWith("/dashboard/oauth")
                            ? <OAuthLayout>
                                {children}
                            </OAuthLayout>
                            : <>{children}</>
                        } */}
                        {children}
                    </div>
                    {/* <OnboardingModalController /> */}
                    <WelcomeModalController />
                    <WhatsNewModal />
                </div >
            </main >
        </div>
    )
}

const MaintainanceBanner = () => {
    return (
        <Box className='flex aic' bg="red" p="sm" style={{ gap: 15 }}>
            <AlertCircle size={20} color='#fff' />
            <Box>
                <Text fw={600} lh={1} c="#fff">Maintenance is taking place between 9AM-10:30AM CET</Text>
                <Text fz="sm" lh={1} mt={6} c="rgba(255,255,255,.6)">You may experience a slowdown when using the billing page. We apologise for any inconvenience this causes.</Text>
            </Box>
        </Box>
    )
}


export default function DashboardLayout({ children }: { children: React.ReactNode }) {
    return (
        <UserProvider>
            <PlatformsProvider>
                <ManualGrantsProvider>
                    <AgencySelectModalProvider>
                        <AgencyProvider>
                            <CouponProvider>
                                <SubscriptionProvider>
                                    <ClientsProvider>
                                        {/* <MaintainanceBanner /> */}
                                        <DashboardHeader />
                                        <div className='flex' style={{ height: "calc(100vh - 80px)", overflowX: "auto", position: "relative" }}>
                                            <Navbar />
                                            <MainContentController>{children}</MainContentController>
                                        </div>
                                    </ClientsProvider>
                                </SubscriptionProvider>
                            </CouponProvider>
                        </AgencyProvider>
                    </AgencySelectModalProvider>
                </ManualGrantsProvider>
            </PlatformsProvider>
        </UserProvider>
    )
}
